import { FieldValidationMetaInfo } from "@vee-validate/i18n";
import { required } from "@vee-validate/rules";

export const requiredAllowFalse = (value: unknown) => {
  if (value !== false) {
    return required(value);
  }
  return true;
};

export const requiredIf = (
  value: unknown,
  [target, targetValue]: [string, string | undefined],
  ctx: FieldValidationMetaInfo
) => {
  // チェック対象の値が undefined の場合 (disabled になっているなど) 、バリデーションをスキップする
  if (typeof value === "undefined") {
    return true;
  }
  // required_if:hoge,1 のパターン
  // rules では文字列を定義していたが、フォームには文字列ではない値が入っていることがあるため == で比較する
  // eslint-disable-next-line eqeqeq
  if (ctx.form[target] == targetValue) {
    return requiredAllowFalse(value);
  }
  // required_if:hoge のパターン
  if (typeof targetValue === "undefined" && ctx.form[target]) {
    return required(value);
  }
  return true;
};
