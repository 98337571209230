import { configure, defineRule, Field, Form } from "vee-validate";
import { localize, setLocale } from "@vee-validate/i18n";
import ja from "@vee-validate/i18n/dist/locale/ja.json";
import * as rules from "@vee-validate/rules";
import { differenceInMinutes } from "date-fns";
import { TimeRange } from "@/types/TimeRange";
import ValidationObserver from "@/components/organisms/validation/ValidationObserver.vue";
import ValidationProvider from "@/components/organisms/validation/ValidationProvider.vue";
import { requiredAllowFalse, requiredIf } from "@/plugins/vee-validate/rules";

Object.entries(rules).forEach(([id, validator]) => {
  // @ts-ignore
  defineRule(id, validator);
});

defineRule("strict_password", (value: string) => {
  const hankakuNumber = /[0-9]/;
  const hankakuEnglish = /[a-zA-Z]/;
  const hankakuSymbol = /[!-/:-@[-`{-~]/;
  return (
    hankakuNumber.test(value) &&
    hankakuEnglish.test(value) &&
    hankakuSymbol.test(value)
  );
});

// 時給の最大値
defineRule("hourlyWageMax", rules.max_value);

// 時給の最小値
defineRule("hourlyWageMin", rules.min_value);

defineRule("range", (value: TimeRange) => {
  const diff = differenceInMinutes(value.endDate, value.startDate);
  if (diff >= 60 * 24) {
    return "24時間を超える勤務時間は設定できません";
  }
  return diff >= 60;
});

defineRule("atLeast", (value: TimeRange, [minimalRestTime]: [string]):
  | string
  | boolean => {
  const diff = differenceInMinutes(value.endDate, value.startDate);
  if (minimalRestTime === "null") {
    return "休憩時間が取得できませんでした。再入力してください";
  }
  if (Number(minimalRestTime) > 0 && diff < Number(minimalRestTime)) {
    return `休憩時間を少なくとも${minimalRestTime}分以上で設定してください`;
  }
  return diff >= 0;
});

defineRule("needRest", (value: boolean, [workTime]: [number]):
  | string
  | boolean => {
  if (!value && workTime > 60 * 6) {
    return `勤務時間が6時間を超過する場合は休憩時間が必須です。`;
  }
  return true;
});

defineRule("restRange", (value: TimeRange, [min, max]: [string, string]) => {
  if (
    value.startDate.getTime() < Number(min) ||
    value.startDate.getTime() > Number(max) ||
    value.endDate.getTime() < Number(min) ||
    value.endDate.getTime() > Number(max)
  ) {
    return "休憩時間は勤務時間内で設定してください";
  }
  if (value.startDate.getTime() === Number(min)) {
    return "休憩開始時間は勤務開始時間より後に設定する必要があります";
  }
  if (value.endDate.getTime() === Number(max)) {
    return "休憩終了時間は勤務終了時間より前に設定する必要があります";
  }
  const range = value.endDate.getTime() - value.startDate.getTime();
  return range > 0;
});

// 募集終了日時は募集開始日時や勤務開始日時より後になるようにする
defineRule(
  "valid_visible_from_to",
  (
    visibleFromOffsetDays: number,
    [visibleFromTime, visibleToOffsetDays, visibleToTime, startWorkTime]: [
      string,
      string,
      string,
      string
    ]
  ) => {
    if (Number(visibleToOffsetDays) === 0 && visibleToTime > startWorkTime) {
      return "募集終了日時が勤務開始以前になるように入力してください";
    }

    if (
      visibleFromOffsetDays > Number(visibleToOffsetDays) ||
      (visibleFromOffsetDays === Number(visibleToOffsetDays) &&
        visibleFromTime >= visibleToTime)
    ) {
      return "募集開始日時が募集終了日時より前になるように入力してください";
    }
    return true;
  }
);

defineRule("hankaku_kana", (value: string) => !!value.match(/^[ｦ-ﾟ]*$/));

defineRule("furigana", (value: string) => !!value.match(/^[ｦ-ﾟ0-9A-Za-z]*$/));

defineRule("required_if", requiredIf);

defineRule("required_allow_false", requiredAllowFalse);

configure({
  /* eslint-disable @typescript-eslint/naming-convention */
  generateMessage: localize("ja", {
    ...ja,
    messages: {
      ...ja.messages,
      // デフォルトだとエラーメッセージが、"メールアドレスは有効なメールアドレスではありません"
      // という不自然なものになるので、emailエラーメッセージを更新
      email: "メールアドレスが正しく入力できているかご確認ください",
      strict_password:
        "パスワードは半角の英字、数字、記号を少なくとも一つずつ使用して入力してください",
      hourlyWageMax: "{field}は0:{max}円以下で設定してください",
      hourlyWageMin: "{field}は0:{min}円以上で設定してください",
      range: "勤務時間が1時間以上になるように設定してください",
      atLeast: "休憩時間の範囲が不正です",
      restRange: "休憩時間の範囲が不正です",
      hankaku_kana: "半角カナを使用して入力してください",
      furigana: "半角英数字カナを使用して入力してください",
      required_allow_false: ja.messages.required
    }
  }),
  /* eslint-enable */
  validateOnBlur: true
});
setLocale("ja");

export {
  ValidationObserver,
  ValidationProvider,
  Field as ValidationField,
  Form as ValidationForm
};
