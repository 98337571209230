import "@babel/polyfill";
import "mutationobserver-shim";
import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// NOTE: plugins 以下から import するのがベストプラクティスなのか悩ましいが、一旦既存の構成に合わせておく
import { BootstrapVue, BootstrapVueIcons } from "@/plugins/bootstrap-vue";
import TextFieldGroup from "@/components/molecules/form/TextFieldGroup.vue";
import Loading from "@/components/organisms/common/Loading.vue";
import { LoadingManager } from "@/plugins/loading";
import {
  ValidationField,
  ValidationForm,
  ValidationObserver,
  ValidationProvider
} from "@/plugins/vee-validate";
import TextareaAutosize from "vue-textarea-autosize";
// Vue3 用の vue-select 型定義は存在しない
// @ts-ignore
import VueSelect from "vue-select";

import "vue-select/dist/vue-select.css";
import "@/assets/scss/custom.scss";
import { masterStore } from "./store/master";

import { initSentry, initMarketoMunchkin } from "./externalModuleInitializer";

// グローバルに外部モジュールをコールする場合、ここに記載する。
initMarketoMunchkin();

async function init() {
  // masterを取得してから起動
  await masterStore.fetchMaster();

  const app = createApp(App)
    .use(router)
    .use(store)
    .use(BootstrapVue)
    .use(BootstrapVueIcons)
    .use(TextareaAutosize)
    .component("ValidationField", ValidationField)
    .component("ValidationForm", ValidationForm)
    .component("ValidationObserver", ValidationObserver)
    .component("ValidationProvider", ValidationProvider)
    .component("TextFieldGroup", TextFieldGroup)
    .component("VSelect", VueSelect)
    .use({
      install: app => {
        const node = document.createElement("div");
        document.body.appendChild(node);

        const loading = createApp({
          components: {
            Loading
          },
          render: () => h(Loading, { ref: "loading" })
        }).mount(node);
        app.config.globalProperties.$loading = new LoadingManager(loading);
      }
    });

  initSentry(app);

  const instance = app.mount("#app");

  // router.app は削除されたため自前で再設定する
  // https://router.vuejs.org/guide/migration/#Removal-of-router-app
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (router as any).app = instance;
}

init();
