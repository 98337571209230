import axios from "axios";
import { authStore } from "@/store/auth";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL_BASE
});

axiosInstance.interceptors.request.use(config => {
  const token = authStore.token;

  if (!token) {
    return config;
  }

  config.headers.common["Authorization"] = `JWT ${token}`;

  return config;
});

export const axiosClient = axiosInstance;
